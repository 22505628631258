export function AppleIcon(props) {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9388 4.38449C7.26724 4.38449 6.22769 3.62101 5.13295 3.6486C3.68863 3.667 2.3639 4.48568 1.61874 5.78269C0.119215 8.38592 1.23236 12.231 2.69508 14.3467C3.41264 15.3769 4.259 16.5359 5.38134 16.4991C6.45768 16.4532 6.86246 15.8 8.16879 15.8C9.46592 15.8 9.8339 16.4991 10.9746 16.4715C12.1338 16.4532 12.8697 15.4229 13.5781 14.3835C14.3969 13.1876 14.7372 12.0286 14.7556 11.9642C14.728 11.955 12.5018 11.0995 12.4742 8.5239C12.4558 6.37141 14.2313 5.34116 14.3141 5.29516C13.3021 3.81418 11.7474 3.6486 11.2046 3.61181C9.7879 3.50142 8.60117 4.38449 7.9388 4.38449ZM10.3307 2.21361C10.9286 1.49611 11.3242 0.493457 11.2138 -0.5C10.3583 -0.463205 9.32793 0.0703173 8.71156 0.787814C8.15959 1.42252 7.68122 2.44358 7.81001 3.41863C8.75756 3.49222 9.73271 2.9311 10.3307 2.21361Z"
        fill="#C0C0C0"
      />
    </svg>
  );
}
export default AppleIcon();
